
    // mobileS: '320px',
    // mobileM: '375px',
    // mobileL: '425px',
    // tablet: '768px',
    // laptop: '1024px',
    // laptopL: '1440px',
    // desktop: '2560px',

export const tablet = '768px'

export const laptop = '1024px'

export const laptopL = '1440px'